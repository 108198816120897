import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import Routes from "./Routes";
import { create } from "jss";
import rtl from "jss-rtl";
import { createTheme } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { StylesProvider, ThemeProvider, jssPreset } from "@mui/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import { heIL } from "@mui/material/locale";

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const theme = createTheme(
  {
    direction: "rtl",
  },
  heIL
);

ReactDOM.render(
  <StylesProvider jss={jss}>
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  </StylesProvider>,

  document.querySelector("#root")
);
