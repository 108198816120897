import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { visuallyHidden } from "@mui/utils";
import { useSearchParams } from "react-router-dom";
import API from "../../api";
import { isKore } from "../../utils";
import HSBar from "react-horizontal-stacked-bar-chart";
import logo from "../../analyticsIcon2.gif";
function descendingComparator(a, b, orderBy) {
  // orderBy = "ctr";
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function acendingComparator(a, b, orderBy) {
  // orderBy = "ctr";
  if (b[orderBy] < a[orderBy]) {
    return 1;
  }
  if (b[orderBy] > a[orderBy]) {
    return -1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => acendingComparator(a, b, orderBy);
}

const headCells = [
  {
    id: "id",
    numeric: false,
    label: "מס",
  },
  {
    id: "title",
    numeric: false,
    label: "כותרת",
  },
  {
    id: "url",
    numeric: false,
    label: "קישור",
  },
  {
    id: "author",
    numeric: false,
    label: "שם הכתב",
  },
  {
    id: "pubdate",
    numeric: false,
    label: "תאריך פרסום",
  },
  {
    id: "views",
    numeric: true,
    label: "צופים כרגע",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    //console.log(property);
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"right"}
            //padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              align={"left"}
            > */}
            <h4> {headCell.label}</h4>
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
            {/* </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTable = ({ extended }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  //console.log(searchParams.entries());

  const updateData = async (articleIds) => {
    setIsLoading(true);
    try {
      const pinnedArticlesRaw = await API.getPinnedArticles();
      let pinnedArticles = pinnedArticlesRaw.map((a) => {
        a = a.map((i) => ({ url: i }));
        return a;
      });

      const flattenIds = pinnedArticlesRaw.flat();

      const loadArticlesMetaP = Promise.allSettled(
        flattenIds.map(async (fid) => {
          const data = await API.getArticleData(
            fid.replace(!isKore ? "/news/" : "/viewArticle/", "")
          );
          if (!data || !data.title) return;
          pinnedArticles = pinnedArticles.map((e) => {
            e = e.map((i) => {
              if (i.url != fid) return i;
              i = { ...data, ...i };
              return i;
            });
            return e;
          });
        })
      );

      const loadAnalytics = async () => {
        const data = await API.getAnalyticsRealtimeData(
          flattenIds.join(",").replaceAll(!isKore ? "/news" : "/viewArticle", "")
        );
        if (!data.rows) return;
        data.rows.forEach((r) => {
          pinnedArticles = pinnedArticles.map((e) => {
            e = e.map((i) => {
              if (!i.url.endsWith(r[0].replace(!isKore ? "www.bhol.co.il/" : "", ""))) return i;
              i = { ...i, views: Number(r[2]) };
              //console.log(i);

              return i;
            });
            return e;
          });
        });
      };
      const loadAnalyticsP = loadAnalytics();

      await Promise.allSettled([loadArticlesMetaP, loadAnalyticsP]);

      const colorFromDt = (dtStr, len) => {
        //dtStr = "06/03/2022 18:00";
        dtStr += ":00";
        const [dateStr, timeStr] = dtStr.split(" ");
        const dateSplited = dateStr.split(dateStr.includes("/") ? "/" : ".");
        const date = new Date(`${dateSplited[1]}-${dateSplited[0]}-${dateSplited[2]} ` + timeStr);

        const now = Date.now() / 1000;
        const then = date.getTime() / 1000;
        const GREEN = "#ccffcc";
        const ORANGE = "#ffa31a";
        const RED = "#ff471a";
        const hours = (n) => n * 60 * 60;

        if (len == 1) {
          if (now - then < hours(4)) return GREEN;

          if (now - then < hours(6) && now - then > hours(4)) return ORANGE;

          if (now - then > 6 * 60 * 60) return RED;
        }

        if (len == 4 || (isKore && len == 6)) {
          if (now - then < hours(3)) return GREEN;

          if (now - then < hours(4) && now - then > hours(3)) return ORANGE;

          if (now - then > 4 * 60 * 60) return RED;
        }

        if (len == 8) {
          if (now - then < hours(6)) return GREEN;

          if (now - then < hours(10) && now - then > hours(6)) return ORANGE;

          if (now - then > 10 * 60 * 60) return RED;
        }
      };
      const new_ = [];
      pinnedArticles.forEach((p) => {
        if (p.length == 1) new_.push("ראשית");
        if (p.length == 4 || (isKore && p.length == 6)) new_.push("משניות");
        if (p.length == 8) new_.push("סיפורים חמים");
        p.forEach((a, idx) =>
          new_.push({
            ...a,
            id: idx + 1,
            color: colorFromDt(a.pub_date + " " + a.pub_time, p.length),
          })
        );
      });
      setRows(new_);
    } catch (error) {}
    setIsLoading(false);
    setTimeout(updateData, 63.5 * 1000, articleIds);
  };

  React.useEffect(async () => {
    const _articleIds = searchParams.get("articles") || "";
    setArticleIds(_articleIds);
  }, [searchParams]);

  React.useEffect(async () => {
    updateData();
  }, []);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("views");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [firstTimeload, setFirstTimeload] = React.useState();
  const [showOnlyNews, setShowOnlyNews] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(999);
  const [totalDevicesPrecentage, setTotalDevicesPrecentage] = React.useState({
    desktop: 0,
    mobile: 0,
    tablet: 0,
  });
  const [articleIds, setArticleIds] = React.useState(searchParams.get("articles") || "");
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const isMobile = window.innerWidth < 670;
  return (
    <div>
      {/* <Box sx={{ width: "95%", "max-width": "2000px", margin: "auto", marginTop: "20px" }}>ggg</Box> */}
      <Box
        sx={{
          width: "95%",
          "max-width": "2000px",
          margin: "auto",
          marginTop: extended ? "10px" : "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: extended ? "0px" : "10px",
          }}
        >
          {!extended ? (
            <>
              <img height={"50px"} width={"auto"} src={logo}></img>
              <h1 style={{ fontWeight: "bold", marginRight: "15px", marginLeft: "15px" }}>
                כתבות נעוצות
              </h1>
              <img
                height={"50px"}
                width={"auto"}
                style={{ borderRadius: "9px" }}
                src={isKore ? "https://i.imgur.com/PTmf6qd.png" : "https://i.imgur.com/AvvXZkl.png"}
              ></img>
            </>
          ) : (
            <h2 style={{ fontWeight: "bold", marginRight: "15px" }}>כתבות נעוצות</h2>
          )}
        </div>
        <center>{/* <h1>גוגל דיסקבר</h1> */}</center>

        <br></br>

        <Paper sx={{ mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                //onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />

              <TableBody>
                {rows
                  // .slice()
                  // .sort(getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    if (typeof row == "string")
                      return (
                        <TableRow>
                          <h3 style={{ marginRight: "15px" }}>{row}</h3>
                        </TableRow>
                      );
                    return (
                      <TableRow hover key={index} style={{ background: row.color }}>
                        <TableCell
                          align="right"
                          // style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                          onClick={() => {}}
                        >
                          {row.id}
                        </TableCell>

                        <TableCell align="right">{row.title || "-"}</TableCell>
                        <TableCell align="right">
                          <a
                            target="_blank"
                            href={
                              (!isKore ? "https://www.bhol.co.il/" : "https://www.kore.co.il/") +
                              row.url
                            }
                          >
                            {row.url}
                          </a>
                        </TableCell>
                        <TableCell align="right">{row.author || "-"}</TableCell>
                        <TableCell align="right">{row.pub_time + " " + row.pub_date}</TableCell>
                        <TableCell align="right">
                          <b>{row.views?.toLocaleString() || "0"}</b>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[10, 15, 25, 50, 100]}
            component="div"
            count={rows.length}
            //rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
      </Box>
    </div>
  );
};
export default EnhancedTable;
