import React, { useState } from "react";
import { Navigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { isKore } from "../utils";
const Login = (props) => {
  const [key, setKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formMessage, setFormMessage] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!key) {
      alert("יש למלא את המפתח");
      return;
    }

    const handleResponse = (response) => {
      setIsLoading(false);
      if (!response.ok) {
        setFormMessage("pw");
        return;
      }
      const token = response.token;
      localStorage.setItem("apikey", key);
      setFormMessage("ok");
    };

    setIsLoading(true);
    fetch("/api/auth/check", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "auth_key " + key,
      },
      body: JSON.stringify({ auth_key: key }),
    })
      .then(
        (res) => res.json()
        // if (!res.ok) {
        //   throw Error(res.statusText);
        // }
      )
      .then(
        (result) => {
          handleResponse(result);
        },
        (error) => {
          setIsLoading(false);
          setFormMessage("err");
        }
      );
  };

  const renderFormMessage = (formMessage) => {
    switch (formMessage) {
      case "pw":
        return "מפתח שגוי";
      case "err":
        return "שגיאה באימות הנתונים, יש לפנות למנהל";
      case "ok":
        return <Navigate to={searchParams.get("redirect") || "/"} />;
      default:
        return null;
    }
  };

  return (
    <div className="login-container">
      <form className="login" onSubmit={handleSubmit}>
        <div className="form-header">
          <h3>{isKore ? "כל רגע" : "בחדרי חרדים"}</h3>
          <p>סטטיסטיקות</p>
        </div>

        <div className="form-group">
          <h4>מפתח</h4>
          <input
            type="password"
            placeholder="הזן מפתח"
            name="password"
            value={key}
            onChange={(e) => setKey(e.target.value)}
          />
        </div>
        {formMessage ? (
          <div className="form-group">
            <h4 className="message">{renderFormMessage(formMessage)}</h4>
          </div>
        ) : null}

        <div className="form-group">
          <button type="submit" disabled={isLoading}>
            {isLoading ? <i className="fa fa-circle-o-notch fa-spin"></i> : <p>התחברות</p>}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
